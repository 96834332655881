import { Document } from '@contentful/rich-text-types';
import { ComponentProps } from 'react';

import { FlexibleSectionManager } from 'src/hygiene/sections/SectionManager/FlexibleSectionManager';

import { PageArticleDataNoContent } from './ContentfulGetArticle';

export enum ContentfulObjectType {
  ASSET = 'Asset',
  COMPONENT_STATIC_CONTENT = 'ComponentStaticContent',
  CONTENT_AUTHOR = 'ContentAuthor',
  CONTENT_FAQ = 'ContentFaq',
  SECTION_PAGE_SECTION = 'SectionPageSection',
  SECTION_FLEXIBLE_SECTION = 'SectionPageFlexibleSection',
  SECTION_TRUSTED_PARTNERS = 'ComponentOurTrustedPartners',
  SECTION_NEXT_CAR_CTA = 'SectionNextCarCta',
  SECTION_ACCORDIONS = 'ComponentFinanceQuestions',
  SECTION_RICH_TEXT = 'SectionRichText',
  SECTION_LEFT_RIGHT_IMAGE = 'SectionLeftRightImage',
  PAGE_ARTICLE = 'PageArticle',
}

export enum ContentfulPageSectionType {
  USPS = 'USPs',
  CAR_FINANCE = 'Car Finance',
  CAROUSEL_OCCASION = 'Carousel - Occasion',
  CONTACT_US_GET_IN_TOUCH = 'Contact us get in touch',
  HIGHLIGHT_CARMA_SPONSORSHIPS = 'Highlight Carma Sponsorships',
  HOW_FINANCE_WORKS_HERO = 'How Finance Works Hero',
  FIVE_GREAT_THINGS_ABOUT_FINANCE = 'Five Great Things About Finance',
  FINANCE_PROVIDERS = 'Finance Providers',
  TRADE_IN_USPS = 'Trade In Usps',
  HOW_TRADE_IN_WORKS = 'How Trade In Works',
  SOCIAL_PROOF = 'Social Proof',
  BENEFIT = 'Benefit',
  OUR_COMMITMENT = 'Our Commitment',
  FLEXIBLE_PAYMENT = 'Flexible Payment',
  HOW_IT_WORKS = 'How It Works',
}

export interface ContentfulRichText {
  json: Document;
}

export interface ContentfulNavigation {
  text: string;
  link: string;
}

export interface ContentfulObject<T extends ContentfulObjectType> {
  __typename: T;
}

export interface ContentfulCollection<T extends ContentfulObject<ContentfulObjectType>> {
  items: T[];
}

interface ContentfulImage extends ContentfulObject<ContentfulObjectType.ASSET> {
  url: string;
}

export interface ContentfulStaticContent extends ContentfulObject<ContentfulObjectType.COMPONENT_STATIC_CONTENT> {
  internalName: string;
  title: string;
  body: ContentfulRichText;
}

export interface ContentfulEntry<T extends ContentfulObjectType> extends ContentfulObject<T> {
  sys: {
    id: string;
  };
}

export interface ContentfulFaqItem extends ContentfulEntry<ContentfulObjectType.CONTENT_FAQ> {
  question: string;
  answer: ContentfulRichText;
}

export interface ContentfulSectionPageSection extends ContentfulEntry<ContentfulObjectType.SECTION_PAGE_SECTION> {
  sectionType: ContentfulPageSectionType;
}

export interface ContentfulContentAuthor extends ContentfulEntry<ContentfulObjectType.CONTENT_AUTHOR> {
  firstName: string;
  lastName: string;
  image: {
    url: string;
    width: number;
    height: number;
  };
}

export interface ContentfulPageArticle extends ContentfulEntry<ContentfulObjectType.PAGE_ARTICLE> {
  // TODO
}

export interface ContentfulSectionFlexibleSection
  extends ContentfulEntry<ContentfulObjectType.SECTION_FLEXIBLE_SECTION> {
  sys: {
    id: string;
    publishedAt: string;
  };
  sectionBackground: ComponentProps<typeof FlexibleSectionManager>['flexibleSection']['sectionBackground'];
  sectionContent: ComponentProps<typeof FlexibleSectionManager>['flexibleSection']['sectionContent'];
  linkedEntriesCollection: ContentfulCollection<ContentfulContentAuthor | PageArticleDataNoContent>;
}

export interface ContentfulTrustedPartnersSection
  extends ContentfulEntry<ContentfulObjectType.SECTION_TRUSTED_PARTNERS> {
  header: string;
  logoListingCollection: ContentfulCollection<ContentfulImage & { title: string }>;
}

export interface ContentfulNextCarCtaSection extends ContentfulEntry<ContentfulObjectType.SECTION_NEXT_CAR_CTA> {
  sectionTitle: string;
  sectionImage: ContentfulImage;
  ctaLabel: string;
  ctaUrl: string;
}

export interface ContentfulAccordionsSection extends ContentfulEntry<ContentfulObjectType.SECTION_ACCORDIONS> {
  title: string;
  firstAccordionOpen: boolean;
  faqsCollection: ContentfulCollection<ContentfulFaqItem>;
  ctaButtonText: string;
  ctaButtonLink: string;
}

export interface ContentfulRichTextSection extends ContentfulEntry<ContentfulObjectType.SECTION_RICH_TEXT> {
  title: string;
  content: string;
}

export interface ContentfulLeftRightImageSection
  extends ContentfulEntry<ContentfulObjectType.SECTION_LEFT_RIGHT_IMAGE> {
  header?: string;
  imgUrl?: ContentfulImage;
  content: string;
  btnLabel?: string;
  btnUrl?: string;
  reverse?: boolean;
  btnClick?: () => void;
}

export type ContentfulPageSection =
  | ContentfulSectionPageSection
  | ContentfulSectionFlexibleSection
  | ContentfulTrustedPartnersSection
  | ContentfulNextCarCtaSection
  | ContentfulAccordionsSection
  | ContentfulRichTextSection
  | ContentfulLeftRightImageSection;
