import classNames from 'classnames';
import Link from 'next/link';
import { FC, ReactNode } from 'react';

import { PROMO_4 } from 'src/constants';
import { IconWatchingPeople } from 'src/general/Icons/IconWatchingPeople';
import { AddToWatchList } from 'src/general/components/AddToWatchList/AddToWatchList';
import { Button } from 'src/general/components/Button/Button';
import { Divider } from 'src/general/components/Divider/Divider';
import { ProductImage } from 'src/general/components/ProductImage/ProductImage';
import { PromoPill } from 'src/general/components/PromoPill/PromoPill';
import { formatNumber } from 'src/general/helpers/formatNumber';
import { BaseCarData } from 'src/types/Car.types';

import { Shade } from 'src/general/components/Shade/Shade';
import { StatusPill } from '../StatusPill/StatusPill';
import styles from './ProductCard.module.scss';
import { ProductCardRepaymentsTooltip } from './ProductCardRepaymentsTooltip';

type props = {
  carData: BaseCarData;
  weeklyRepayment: number;
  defaultRate: number;
  imagePriority?: boolean;
  onCardClick?: () => void;
  onWatchlistStatusChange?: () => Promise<void>;
  microcopy?: {
    copy: ReactNode;
  };
} & (
  | {
      variant: 'watchlist';
      getStartedUrl?: string;
    }
  | {
      variant: 'plp';
    }
);

export const ProductCard: FC<props> = ({
  carData,
  weeklyRepayment,
  defaultRate,
  imagePriority,
  onWatchlistStatusChange,
  microcopy,
  onCardClick,
  ...props
}) => {
  const priceString = formatNumber(carData.price, { prepend: '$' });
  const dutyString = formatNumber(carData.dutyPrice, { prepend: '$' });
  const weeklyRepaymentString = formatNumber(weeklyRepayment, { prepend: '$' });
  const defaultRateString = formatNumber(defaultRate * 100, { decimals: 2, trimZeroDecimals: true, append: '%' });

  const promoPillData = () => {
    const result = [];
    if (carData.promotionTags?.includes(PROMO_4)) {
      result.push({
        key: PROMO_4,
        copy: microcopy?.copy,
        backgroundColor: '#ebf2f5',
        color: 'rgba(21, 13, 44, 0.878431)',
      });
    }
    return result;
  };

  return (
    <div className={classNames(styles.root, styles[props.variant])}>
      {props.variant === 'plp' && (
        <Link
          href={`/product-detail/${carData.sku}`}
          onClick={onCardClick}
          className={classNames(styles.link, 'tileLink', 'shade_container', 'shade_container--grey')}
          prefetch={false}
        >
          <Shade />
          <span className="sr-only">{carData.model}</span>
        </Link>
      )}
      <ProductImage
        key={carData.sku}
        src={carData.imageUrl}
        alt={carData.model}
        className={styles.image}
        imagePriority={imagePriority}
        data-testid="ProductCard-component-image"
      />
      <p className={classNames(styles.model, 'c-fs-body1', 'c-fw-bold')} data-testid="ProductCard-component-titleText">
        {carData.model}
      </p>
      <p className={classNames(styles.variant, 'c-fs-body2')} data-testid="ProductCard-component-variantText">
        {carData.variant}
      </p>
      <p className={classNames(styles.details, 'c-fs-body2')}>
        <span data-testid="ProductCard-component-distanceText">
          {formatNumber(carData.kilometers, { append: ' km' })}
        </span>
        &ensp;•&ensp;<span data-testid="ProductCard-component-transmissionText">{carData.transmission}</span>
      </p>
      <div className={styles.statusPillWrapper}>
        <StatusPill sku={carData.sku} state={carData.state} />
      </div>
      {carData.state === 'available' && <PromoPill promoPillData={promoPillData()} />}
      <aside className={styles.controls}>
        <AddToWatchList
          key={carData.sku}
          className={styles.watchlist}
          sku={carData.sku}
          product={{
            ...carData,
            title: carData.model,
            price: priceString,
            dutyPrice: dutyString,
          }}
          onWatchlistStatusChange={onWatchlistStatusChange}
        />
      </aside>
      <footer className={styles.footer}>
        <p className={classNames(styles.price, 'c-fs-h5', 'c-fw-bold')} data-testid="ProductCard-component-priceText">
          {priceString}
        </p>
        <div
          className={classNames(styles.repayment, 'c-fs-caption2')}
          data-testid="ProductCard-component-repaymentText"
        >
          <span>
            est. <strong>{weeklyRepaymentString}/wk</strong> based on <strong>{defaultRateString}</strong> p.a.
          </span>{' '}
          <ProductCardRepaymentsTooltip weeklyRepayment={weeklyRepaymentString} defaultRate={defaultRateString} />
        </div>
        {props.variant === 'watchlist' && (
          <>
            <Divider className={styles.footerSeparator}>
              {carData.watchers && (
                <span className={styles.footerSeparatorContent}>
                  <IconWatchingPeople /> <b>{carData.watchers} others</b> watching this car
                </span>
              )}
            </Divider>
            <div className={styles.footerActions}>
              <Button
                href={`/product-detail/${carData.sku}`}
                prefetch={false}
                onClick={onCardClick}
                variant="secondary"
                size="small"
                fullWidth
              >
                View car
              </Button>
              {carData.state === 'available' ? (
                <Button
                  href={props.getStartedUrl ?? `/product-detail/${carData.sku}/get-started`}
                  prefetch={false}
                  variant="primary"
                  size="small"
                  fullWidth
                >
                  Get started
                </Button>
              ) : (
                <Button href={`/product-detail/${carData.sku}/similar`} variant="primary" size="small" fullWidth>
                  View similar
                </Button>
              )}
            </div>
          </>
        )}
      </footer>
    </div>
  );
};
