import classNames from 'classnames';
import { FC } from 'react';

import { isEmpty } from 'lodash';
import { Image } from 'src/general/components/Image/Image';
import { FlexibleSection } from '../flexibleContent.type';
import { getSectionClasses } from '../getSectionClasses.helper';
import styles from './CarmaSponsorships.module.scss';

interface Props extends FlexibleSection {
  sectionData: {
    title: string;
    logos: Array<{ url: string; title: string }>;
    subtext?: string;
  };
  className?: string;
}

export const CarmaSponsorships: FC<Props> = ({
  sectionData: { logos, title, subtext },
  className,
  sectionBackground,
}) => {
  return (
    <section className={classNames(styles.root, className, getSectionClasses(sectionBackground))}>
      <div className={styles.contentWrapper}>
        <header className={styles.header}>
          <h2 className={classNames(styles.title, 'c-fs-h4', 'c-fw-bold')}>{title}</h2>
        </header>
        <div className={styles.logosWrapper}>
          {logos.map((logo) => (
            <div className={styles.logo} key={logo.title}>
              <Image src={logo.url} alt={logo.title} width={111} height={111} />
            </div>
          ))}
        </div>
        {!isEmpty(subtext) && <div className={classNames(styles.subtext, 'c-fs-h6')}>{subtext}</div>}
      </div>
    </section>
  );
};
